.row{
  margin: 1%;
  width: 100%;
}

.btn{
  margin: 1%;
}

.form-filter{
  margin-bottom: 1%;
  padding: 15px !important;
}

input {
  padding: .5rem;
  font-size: 14px;
  width: 100%;
  display: block;
  border-radius: 4px;
  border: 1px solid #ccc;
}

input:focus {
  border-color: #007eff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 0 3px rgba(0, 126, 255, 0.1);
  outline: none;
}

input.error {
  border-color: red;
}
